<template>
  <div class="sermon-grid" v-if="sermons.length">
    <div v-if="title" class="row">
      <div class="col">
        <h4 class="line-right">{{ title }}</h4>
      </div>
    </div>
    <div class="card-deck sermon-deck flex-wrap wd-sm-100p">
      <template v-for="sermon in sermons">
        <div class="card mg-b-15" v-bind:key="sermon.id">
          <div class="card-img-top" v-bind:style="{backgroundImage: 'url('+sermon.thumbnailUrl+')'}">
            <div class="d-none">{{sermon.title}}</div>
          </div>
          <div class="card-body">
            <router-link :to="{ name:'sermons.show', params:{ id: sermon.id }}" class="stretched-link"></router-link>
            <h5 class="card-title">{{sermon.title}}</h5>
            <h6 class="card-subtitle text-muted">{{sermon.church_name}}</h6>
            <h6 class="card-subtitle text-muted mg-t-0">{{sermon.date|format_date}}</h6>
            <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
          </div>
        </div>
      </template>
    </div>
    <nav aria-label="navigation" class="row justify-content-center" v-if="pagination && meta.last_page > 1">
      <ul class="pagination mg-b-0">
        <li class="page-item" :class="{disabled: page == 1}">
          <a class="page-link page-link-icon" href="#" v-on:click.prevent="setPage(meta.current_page - 1)"><FeatherIcon icon="chevron-left"/></a>
        </li>
        <li class="page-item" v-if="page > 3">
          <a class="page-link" href="#" v-on:click.prevent="setPage(1)">1</a>
        </li>
        <li class="page-item disabled" v-if="page > 4">
          <a class="page-link page-link-icon" href="#"><FeatherIcon icon="more-horizontal"/></a>
        </li>
        <template v-for="i in range(meta.current_page-2, meta.current_page+2)">
          <li class="page-item" :class="{active: meta.current_page == i}" v-bind:key="i">
            <a class="page-link" href="#" v-on:click.prevent="setPage(i)">{{i}}</a>
          </li>
        </template>
        <li class="page-item disabled" v-if="meta.last_page > page + 3">
          <a class="page-link page-link-icon" href="#"><FeatherIcon icon="more-horizontal"/></a>
        </li>
        <li class="page-item" v-if="meta.last_page > page + 3">
          <a class="page-link" href="#" v-on:click.prevent="setPage(meta.last_page)">{{meta.last_page}}</a>
        </li>        
        <li class="page-item" :class="{'disabled': page == meta.last_page}">
          <a class="page-link page-link-icon" href="#" v-on:click.prevent="setPage(meta.current_page + 1)"><FeatherIcon icon="chevron-right"/></a>
        </li>
      </ul>
    </nav>

  </div>
</template>

<script>
  import _ from 'lodash'
  import FeatherIcon from '~/components/FeatherIcon'

  /**
    current_page: 1
    from: 1
    last_page: 3
    path: "http://dev.allsermons.vm/api/v1/sermons"
    per_page: "2"
    to: 2
    total: 5
 */
  export default {
    name: 'SermonGrid',
    props: {
      limit: { type: Number, default: 12 },
      title: { type: String, default: null },
      query: { type: Object, default: null },
      pagination: { type: Boolean, default: true },
      ignore: { type: Array, default: null },
    },
    components: {
      FeatherIcon
    },
    data: () => ({
      page: 1,
      sermons: [],
      meta: {}
    }),
    created () {
      this.fetch();
    },
    methods: {
      async fetch () {
        let { sermons, meta } = await this.$store.dispatch('public/fetchSermons', { 
          limit: this.limit, 
          page: this.page, 
          ignore: this.ignore, 
          ...this.query
        });
        this.sermons = sermons;
        this.meta = meta;
      },
      range(start, end){
        return _.range(Math.max(start, 1), Math.min(end + 1, this.meta.last_page + 1));
      },
      setPage(n) {
        this.page = n;
        this.fetch();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card {
    position:relative; 
    margin-bottom: 1em;
    @include media-breakpoint-up(sm) {
      max-width: calc(50% - 30px);
      flex-basis: calc(50% - 30px);
    }
    @include media-breakpoint-up(lg) {
      max-width: calc(33% - 30px);
      flex-basis: calc(33% - 30px);
    }
  }
  .card-img-top {
    background: no-repeat center center;
    background-size: cover;
    height: 43vw;
    @include media-breakpoint-up(sm) {
      height: 8rem;
    }
    @include media-breakpoint-up(md) {
      height: 11rem;
    }
    @include media-breakpoint-up(lg) {
      height: 10rem;
    }
    @include media-breakpoint-up(xl) {
      height: 12rem;
    }
  }
</style>
