<template>
  <div class="container-fluid">
    <div id="player-row" class="row bg-gray-900">
      <div class="container">
        <div class="placeholder-paragraph aligned-centered pd-y-70" v-if="loading">
          <div class="line"></div>
          <div class="line"></div>
        </div>
        
        <div class="row justify-content-center" v-if="!loading">
          <!-- youtube iframe with progressive enhancement (extra queries after the url to optimize the embed) -->
          <vue-plyr class="wd-100p" ref="plyr" :emit="['seeked', 'timeupdate']" @timeupdate="videoTimeUpdated" @seeked="videoTimeSeeked">
            <div class="plyr__video-embed">
              <iframe v-bind:src="url" allowfullscreen allowtransparency allow="autoplay"></iframe>
            </div>
          </vue-plyr>
        </div>
      </div>
    </div>
    <div class="row bg-gray-200" v-if="!loading">
      <div class="container pd-t-10">
        <div class="row">
          <div class="col-md col-lg-6">
            <h3 class="mb-0">{{sermon.title}}</h3>
            <h5 class="mb-0 text-muted">
              <small class="text-muted">
                <router-link :to="{ name:'churches.show', params:{id: sermon.church_id, name: slugify(sermon.church_name)}}">
                  {{sermon.church_name}}
                </router-link>
              </small>
              <small v-if="sermon.speaker" class="text-muted"> | {{sermon.speaker.name}}</small>
            </h5>
            <h5 class="mb-3 text-muted">
              <small class="text-muted">{{sermon.date|format_date}}</small>
            </h5>
          </div>
          <div class="col-md col-lg-6">
            <div id="actions" class="lh-10 d-flex justify-content-lg-end">
              <span class="text-muted  mg-r-15">{{sermon.view_count|smart_num}} views</span>

              <button type="button" class="btn btn-sm btn-outline-danger tx-danger pd-y-4" data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" v-if="!user">
                <fi icon="heart"/>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="wd-200 pd-15">
                  <p>To Follow a church please login or create an account</p>
                  <nav class="nav">
                    <router-link :to="{name: 'login'}" class="nav-link">{{ $t('login') }}</router-link>
                    <router-link :to="{name: 'register'}" class="nav-link">{{ $t('register') }}</router-link>
                  </nav>
                </div>
              </div>

              <button type="button" class="btn btn-sm btn-outline-danger tx-danger pd-y-4" v-if="user && !liked" v-on:click="like">
                <fi icon="heart"/>
              </button>
              <button type="button" class="btn btn-sm btn-danger pd-y-4 animated heartBeat" v-if="user && liked" v-on:click="unlike">
                <fi icon="heart"/>
              </button>
              
            </div>
            <!-- <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Topics</label> -->
            <ul class="list-inline list-inline-skills">
              <template v-for="topic in sermon.topics">
                <li class="list-inline-item" v-bind:key="topic.id">
                  <router-link :to="{name: 'topics.show', params: {id: topic.id, text: topic.topic}}">
                    {{topic.hash}}
                  </router-link>
                </li>
              </template>
            </ul>
          </div><!-- col -->
        </div>
        <div class="description" v-if="sermon.description != 'Youtube' && sermon.description != 'Vimeo'">
          <p>{{sermon.description}}</p>
        </div>
      </div><!-- container -->
    </div><!-- row -->
    <div class="row" v-if="!loading">
      <div class="container">
        <div class="row" v-if="sermon.series">
          <div class="container pd-t-10">
            <sermon-grid :title="sermon.series.title + ': ' + format_date(sermon.series.start_date) + '-' + format_date(sermon.series.end_date)" :query="{series_id: sermon.series_id}" :ignore="[sermon.id]"></sermon-grid>
          </div><!-- container -->
        </div><!-- row -->

        <div class="row">
          <div class="container pd-t-10">
            <sermon-grid :title="'More sermons from ' + sermon.church_name" :limit="3" :query="{church_id: sermon.church_id}" :pagination="false" :ignore="sermon.series ? sermon.series.sermon_ids : [sermon.id]"></sermon-grid>
          </div><!-- container -->
        </div><!-- row -->        

        <div class="row" v-for="topic in sermon.topics" v-bind:key="topic.id" >
          <div class="container pd-t-10">
            <sermon-grid :title="'Similar videos about ' + topic.text" :limit="3" :query="{topic_id: topic.id}" :pagination="false" :ignore="[sermon.id]"></sermon-grid>
          </div><!-- container -->
        </div><!-- row -->
      </div><!-- container -->
    </div><!-- row -->
  </div>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import { mapGetters } from 'vuex'
  import  VuePlyr from 'vue-plyr'
  import SermonGrid from '~/components/SermonGrid'
  import FeatherIcon from '~/components/FeatherIcon'

  export default {
    layout: 'player',
    components: {
      'vue-plyr': VuePlyr,
      'sermon-grid': SermonGrid,
      'fi': FeatherIcon,
    },
    metaInfo () {
      return { title: this.title }
    },
    data () {
      return {
        sermon: null,
        title: window.config.appName,
        id: this.$route.params.id,
        loading: true,
        prevTime: 0,
        elapsedTime: 0,
        viewed: false
      };
    },
    mounted () {
      this.fetch();
    },
    watch: {
      '$route.params.id': function () {
        this.fetch()
      }
    },
    computed: {
      // sermon() {
      //   return this.sermons[this.id];
      // },
      url () {
        if (this.sermon) {
          return this.sermon.videoUrl + '?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1';
        } else {
          return '#';
        }
      },
      liked () {
        return this.user && 
          this.user.likes.length > 0 && 
          _.indexOf(this.user.likes, this.sermon.id) !== -1;
      },
      ...mapGetters({
        user: 'auth/user',
        sermons: 'public/sermons'
      })
    },
    methods: {
      format_date(date){
        return this.$options.filters.format_date(date);
      },
      fetch() {
        if (!this.sermon || this.sermon.id != this.$route.params.id) {
          console.log("Fetching...");
          this.loading = true;
          this.$store.dispatch('public/fetchSermon', {id: this.$route.params.id})
            .then((sermon) => { 
              this.loading = false; 
              this.sermon = sermon;
              this.title = sermon.title;
              console.log(sermon);
            });
        } else {
          this.loading = false;
          this.title = this.sermon.title;
        }
      },
      async like() {
        if (!this.user) {
          this.$router.push({name: 'login', params: {r: window.location}});
        }
        const { data } = await axios.put('/api/v1/user/me', {action: 'like', sermon: this.sermon.id});
      
        this.$store.dispatch('auth/updateUser', {user: data.user});
      },
      async unlike() {
        const { data } = await axios.put('/api/v1/user/me', {action: 'unlike', sermon: this.sermon.id});
      
        this.$store.dispatch('auth/updateUser', {user: data.user});
      },
      view: _.debounce(function() { // Make sure our function doesn't get called too many times (~60 sec)
        axios.put('/api/v1/sermons/' + this.sermon.id, {action: 'view', sermon: this.sermon.id})
          .then(() => { this.viewed = true; });
      }, 60000, {leading: true}), 
      videoTimeUpdated(event) {
        const instance = event.detail.plyr;
        const delta = instance.currentTime - this.prevTime;
        
        // If already marked as viewed then ignore
        if (this.viewed) { return; }
        
        this.elapsedTime += delta;        
        this.prevTime = instance.currentTime;

        // If enough viewing time has elapsed then notify app of our view
        if (this.elapsedTime > 60) {
          this.view();
        }
      },
      videoTimeSeeked(event) {
        const instance = event.detail.plyr;
        this.prevTime = instance.currentTime;
        console.log('time seeked', instance.currentTime);
      },
    }
  }
</script>

<style src="plyr/dist/plyr.css"></style>
<style lang="scss" scoped>
.list-inline-skills .list-inline-item {
  margin-right: 0;
}
</style>
